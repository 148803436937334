import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import ProjectsDropDown from "../../components/layout/CrmProject";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/crm-executives";

export const paths = {
  crm: "/crm",
  sales: "/sales",
  Apartment: "/src/components/sales-manager/availability/ApartmentModal.js",
  booking: "../sales-manager/BookingDetails.js",
  costSheet: "../sales-manager/CostSheet.js",
  dashboard: "/dashboard",
  legal_documents: "/legal_documents",
  reports: "/reports",
  digital_approval: "/digital_approval",
  customerDelight:"/CustomerDelight",
  apartmentHandover:"/apartmentHandover",
  
};

export const landing = `${pathName}${paths.dashboard}`;
export const sales = `${pathName}${paths.sales}`;
export const crm = `${pathName}${paths.crm}`;
export const legal_documents = `${pathName}${paths.legal_documents}`;
export const reports = `${pathName}${paths.reports}`;
export const digital_approval = `${pathName}${paths.digital_approval}`;
export const customerDelight= `${pathName}${paths.customerDelight}`;
export const apartmentHandover= `${pathName}${paths.apartmentHandover}`;

// export const pages = {
//     crm_listing: `${pathName}${paths.crm}`,
//     crm_details: `${pathName}${paths.crm}/view/:customerId`,
//     sales: `${pathName}${paths.sales}`,
//     avail: `${pathName}${paths.avail}`,
//     TowerReport: `${pathName}${paths.avail}`,
//     Apartment: `${pathName}${paths.Apartment}`,
//     booking: `${pathName}${paths.booking}`,
//     costSheet: `${pathName}${paths.costSheet}`,
// };

export const menu = [
  {
    title: "Dashboard",
    to: landing,
  },
  {
    title: "Sales",
    to: sales,
  },
  {
    title: "CRM",
    to: crm,
  },
  {
    title: "Digital Approval",
    to: digital_approval,
  },
  {
    title: "Legal Module",
    to: legal_documents,
  },
  {
    title: "Reports",
    to: reports,
  },
  {
    title: "Customer Delight",
    to: customerDelight,
  },
  {
    title: "Apartment Handover",
    to: apartmentHandover,
  },

];

export const routes = [
  {
    path: paths.legal_documents,
    exact: true,
    component: lazy(() => import("./legal_documents")),
  },
  
  {
    path: paths.crm,
    exact: true,
    component: lazy(() => import("./crm")),
  },
  {
    path: paths.dashboard,
    exact: true,
    component: lazy(() => import("./dashboard")),
  },
  {
    path: `${paths.reports}`,
    exact: true,
    component: lazy(() => import("./reports")),
  },
  {
    path: `${paths.customerDelight}`,
    exact: true,
    component: lazy(() => import("./CustomerDelight")),
  },
  {
    path: `${paths.apartmentHandover}`,
    exact: true,
    component: lazy(() => import("./apartmentHandover")),
  },
  {
    path: `${paths.apartmentHandover}/view/:customerId`,
    exact: true,
    component: lazy(() => import("../crm-head/Handover/HandoverDetails")),
  },
  {
    path: `${paths.customerDelight}/view/:customerId`,
    component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
  },
  {
    path: `${paths.crm}/view/:customerId`,
    exact: true,
    component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
  },
  {
    path: `${paths.digital_approval}/view_booking/:status/:customerId`,
    exact: true,
    component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
  },
  // {
  //   path: `${paths.crm}/view_rejected_booking/:customerId`,
  //   exact: true,
  //   component: lazy(() => import("./CRM/Bookings/RejectedBookingDetail")),
  // },
  {
    path: `${paths.crm}/view/:customerId/final-notice`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/FinalRemainderLetter"
      )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId`,
    exact: true,
    component: lazy(() => import("./CRM/CustomerPayments/PaymentDetailPage")),
  },
  {
    path: `${paths.crm}/poa/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/PoaInfoAdd")
    ),
  },
  {
    path: `${paths.crm}/poa/:poaId/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/PoaDetailsEdit")
    ),
  },
  {
    path: `${paths.crm}/contact-person/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddContactPerson")
    ),
  },
  {
    path: `${paths.crm}/contact-person/:conId/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/ContactPersonEdit")
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/generate-customer-receipt`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/GenerateCustomerReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/upr`,
    exact: true,
    component: lazy(() =>
      import(
        "../../pages/crm-executives/CRM/CustomerMaster/Upr/CustomerUpr"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/interest`,
    exact: true,
    component: lazy(() =>
    import(
      "../../pages/crm-executives/CRM/CustomerMaster/Interest/CustomerInterest"
    )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId/payment-received-from-customer`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/payment-received-from-customer`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/payment-received-receipt/:receiptId`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/request/add/:requestId/`,
    exact: true,
    component: lazy(() =>
      import(
        "../../pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/generate-customer-receipt/payment-received-receipt/:receiptId`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/credit-notes/:noteId`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CreditNoteDetails"
      )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId/outstanding-payment`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerOutstandingPayments"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/generate-customer-receipt/outstanding-payment`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerOutstandingPayments"
      )
    ),
  },
  {
    path: `${paths.crm}/payment/view/:customerId/payment-statement`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerPaymentStatement"
      )
    ),
  },
  {
    path: `${paths.crm}/view/:customerId/generate-customer-receipt/payment-statement`,
    exact: true,
    component: lazy(() =>
      import(
        "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerPaymentStatement"
      )
    ),
  },
  {
    path: paths.sales,
    exact: true,
    component: lazy(() => import("./sales")),
  },
  {
    path: `${paths.sales}/view/:customerId`,
    // component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
    component: lazy(() => import("./Sales/Cancellation/CancellationAdd")),

  },
  {
    path: `${paths.legal_documents}/view/:customerId`,
    component: lazy(() => import("./LegalDocuments/Documents/LegalDetails")),
  },
  {
    path: `${paths.legal_documents}/register/:customerId`,
    component: lazy(() => import("./LegalDocuments/Registration/RegistrationDetails")
    ),
  },
  {
    path: `${paths.sales}/cancellationview/:customerId`,
    component: lazy(() => import("./CRM/CustomerMaster/CustomerDetail")),
  },
  {
    path: `${paths.sales}/cancellation/add/:customerId`,
    component: lazy(() => import("./Sales/Cancellation/CancellationAdd")),
  },
  // {
  //   path: `${paths.sales}/crm-executive/crm/CustomerDetailsPage/Requestimformation/:customerId`,
  //   component: lazy(() => import("./CRM/CustomerMaster/CancelledCustomer")),
  // },
  {
    path: `${paths.sales}/pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation`,
    component: lazy(() => import("./../../pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation")),
  },
  {
    path: `${paths.sales}/cancellationdisplay/view/:customerId`,
    component: lazy(() => import("./Sales/Cancellation/CancellationDisplayView")),
  },
  {
    path: `${paths.sales}/booking_form/:bookingId`,
    component: lazy(() => import("./Sales/BookingForm/BookingForm")),
  },
  {
    path: `${paths.sales}/apartment/:apartmentId`,
    component: lazy(() => import("../sales-manager/Apartment.js")),
  },
  {
    path: `${paths.sales}/tower-wise-availability`,
    component: lazy(() => import("../sales-manager/TowerAvailability.js")),
  },
  {
    path: `${paths.sales}/tower-reports`,
    component: lazy(() => import("../sales-manager/TowerReport")),
  },
  {
    path: `${paths.sales}/project-cost-sheet`,
    component: lazy(() => import("../sales-manager/ProjectCostSheet")),
  },
  {
    path: `${paths.sales}/booking-details/:bookingId`,
    exact: true,
    component: lazy(() => import("./Sales/BookingForm/BookingDetails")),
  },

  {
    path: `${paths.crm}/bank-details/add/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddBankDetail")
    ),
  },
  {
    path: `${paths.crm}/bank-details/edit/:customerId`,
    exact: true,
    component: lazy(() =>
      import("../../components/crm-executive/crm/editDetails/EditBankDetails")
    ),
  },
  {
    path: `${paths.crm}/co-applicant/add/:customerId`,
    component: lazy(() =>
      import("../../components/crm-executive/crm/Add/AddCoApplicant")
    ),
  },
  {
    path: `${paths.legal_documents}/registration_charges/:customerId`,
    component: lazy(() => import("./LegalDocuments/Registration/RegistrationDetails")
  ),
  },
  {
    path: `${paths.legal_documents}/apartment-plan/:customerId`,
    component: lazy(() => import("./LegalDocuments/Registration/InduvidualPlan")
  ),
  },
  {
    path: `${paths.legal_documents}/aadhar-pan/:customerId`,
    component: lazy(() => import("./LegalDocuments/Registration/CustomerAadharTab")
  ),
  },
  {
    path: `${paths.legal_documents}/registration-remainder/:customerId`,
    component: lazy(() => import("./LegalDocuments/Registration/CustomerRegistrationRemainderTab")
  ),
  },
  {
    path: paths.digital_approval,
    exact: true,
    component: lazy(() => import("./CRM/digitalapprove")),
  },
  
];

export const allowRoles = [11,18,19,20];

export default (
  <AuthRoute path={pathName}>
    <RoleRoutes
      title="CRM - Executives"
      routes={routes}
      menu={menu}
      landing={landing}
      allowRoles={allowRoles}
      headerRight={<ProjectsDropDown />}
    />
  </AuthRoute>
);
