import { useCallback } from "react";
import formatAllData from "../../components/form/formatAllData";

const useFormatAll = (allIds) => {
    return useCallback(
        (values) => {
            return formatAllData(allIds, values);
        },
        [allIds]
    );
};

export default useFormatAll;
