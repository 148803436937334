import React from "react";
// import Select from "react-select";

const NormalSelect = ({
    className = "form-control",
    setValue = null,
    value = "",
    placeholder = "Select an option",
    options = [],
    isLoading = false,
    disabled,
}) => {
    return (
        <select
            value={value}
            onChange={(e) => {
                if (setValue) {
                    setValue(e.target.value);
                }
            }}
            className={className}
            disabled={isLoading || disabled}
        >
            <option value="">{placeholder}</option>
            {options.map((x) => {
                return (
                    <option key={x.value} value={x.value}>
                        {x.label}
                    </option>
                );
            })}
        </select>

        // React-Select

        // <Select
        //     value={value}
        //     placeholder={placeholder}
        //     options={options}
        //     classNamePrefix="react-select"
        //     onChange={(val) => {
        //         if (typeof setValue === "function") {
        //             setValue(val);
        //         }
        //     }}
        //     isLoading={isLoading}
        //     {...ipProps}
        // />
    );
};

export default NormalSelect;
