const _toString = Object.prototype.toString;

export function noop() {}

export function toRawType(value) {
    return _toString.call(value).slice(8, -1);
}

export function isString(v) {
    return typeof v === "string";
}

export function isFunction(v) {
    return typeof v === "function";
}

export function isPlainObject(obj) {
    return _toString.call(obj) === "[object Object]";
}

export function isRegExp(v) {
    return _toString.call(v) === "[object RegExp]";
}

export function isArray(v) {
    return Array.isArray(v);
}

export function toBoolean(v) {
    return !!v;
}

export const toNum = (num = 0) => {
    const pr = parseFloat(num);
    return isNaN(pr) ? 0 : pr;
};

export const mapOpt = (opt) => ({ label: opt.title, value: opt.id });
export const mapOpts = (options = []) => options.map(mapOpt);
