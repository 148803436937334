import React from "react";
import { NavLink } from "react-router-dom";

const PageLi = ({ title, to, exact }) => {
    return (
        <li>
            <NavLink activeClassName="page-list-active" to={to} exact={exact}>
                {title}
            </NavLink>
        </li>
    );
};

const Menu = ({ menu = [] }) => {
    return (
        <div className="page-list-con d-flex justify-content-center align-items-stretch">
            <ul className="page-list">
                {menu.map((page, idx) => (
                    <PageLi {...page} key={idx} />
                ))}
            </ul>
        </div>
    );
};

export default Menu;
