import React from "react";
import { ArrowLeft } from "react-feather";
import { useHistory } from "react-router-dom";

const GoBack = ({ url }) => {
    const history = useHistory();
    if (url) {
        return (
            <button onClick={history.push(url)} className="btn p-0">
                <ArrowLeft size={18} /> Go Back
            </button>
        );
    }
    return (
        <button onClick={history.goBack} className="btn p-0">
            <ArrowLeft size={18} /> Go Back
        </button>
    );
};

export default GoBack;
