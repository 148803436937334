import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from "./Landing";
import NotFound from "./NotFound";
import Logout from "../components/auth/Logout";
import auditor from "./auditor";
import teleCaller from "./tele-caller";
import teleCallerTeamLead from "./tele-caller-team-lead";
import marketingManager from "./marketing-manager";
import marketingManagerExective from "./marketing-manager-executive";
import salesManager from "./sales-manager";
import salesTeamManager from "./sales-manager-team-lead";
import frontOffice from "./front-office-executive";
import salesGm from "./sales-general-manager";
import crmExecutives from "./crm-executives";
import salesCo from "./sales-coordinator";
import CostSheet from "./sales-manager/CostSheet";
import CoastSheetIframe from "./sales-manager/CoastSheetIframe";
import ChangePwdPage from "./ChangePwdPage";
import AuthRoute from "../components/auth/AuthRoute";
import PdfViewer, { DocViewer } from "../components/common/PdfViewer";
import crmHead from "./crm-head";
import legalAdvisor from "./legal-advisor";
import FM from "./fm";


const Pages = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        {marketingManagerExective}
        {teleCaller}
        {teleCallerTeamLead}
        {marketingManager}
        {salesManager}
        {salesTeamManager}
        {frontOffice}
        {salesGm}
        {salesCo}
        {crmExecutives}
        {crmHead}
        {legalAdvisor}
        {FM}
        {auditor}
        <Route path="/cost-sheet-form" component={CostSheet} />
        <Route path="/cost-sheet/:apartmentId" component={CoastSheetIframe} />
        <Route
          path="/pdf-viewer/:docType/:type/:path/:customerId"
          component={PdfViewer}
        />
        <Route path="/doc-viewer/:docType/:path/" component={DocViewer} />

        <Route path="/logout" component={Logout} />

        <AuthRoute path="/change-password" exact component={ChangePwdPage} />
        <Route path="*" component={NotFound} />

      </Switch>
    </BrowserRouter>
  );
};

export default Pages;
