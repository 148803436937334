import { isDate } from "validate.js";
import dayjs from "dayjs";
import formTypes from "./formTypes";

export const formatKEY = "---$---";

const isEmpty = (value) =>
    value === undefined || value === null || value === "";

export default (allIds = [], values) => {
    return allIds.reduce((acc, cur) => {
        const value = values[cur.name];
         if (isEmpty(value)) { // Commented for getting all form field values
            return acc;
        } 
        const val = getValue(cur, value);
        const [start, end] = cur.name.split(formatKEY);
        if (start && end) {
            return {
                ...acc,
                [start]: {
                    ...(acc[start] || {}),
                    [end]: val,
                },
            };
        }
        return { ...acc, [cur.name]: val };
    }, {});
};

export const dateformat = (value) => dayjs(value).format("YYYY-MM-DD");
export const timeformat = (value) => dayjs(value).format("HH:mm:ss");
export const monthyearformat = (value) => dayjs(value).format("YYYY-MM-DD");

export const getValue = (cur, value) => {
    switch (cur.type) {
        case formTypes.select:
            return value
                ? cur.inputProps && cur.inputProps.isMulti
                    ? value.map((value) => value.value)
                    : value.value
                : null;
        case formTypes.year:
            return isDate(value) ? value.getFullYear() : "";
        case formTypes.monthyear:
                return isDate(value) ? monthyearformat(value) : "";    
        case formTypes.date:
            return isDate(value) ? dateformat(value) : "";
        case formTypes.time:
            return isDate(value) ? timeformat(value) : "";
        case formTypes.daterange:
            return value && isDate(value.from) && isDate(value.to)
                ? {
                      from: dateformat(value.from),
                      to: dateformat(value.to),
                  }
                : null;
        case formTypes.timerange:
            return value && isDate(value.from) && isDate(value.to)
                ? {
                      from: timeformat(value.from),
                      to: timeformat(value.to),
                  }
                : null;
        default:
            return value;
    }
};
