import React from 'react'

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center align-items-center abs-full">
            <div className="text-center">
                <h1>404</h1>
                <p>Page not found</p>
            </div>
        </div>
    )
}

export default NotFound
