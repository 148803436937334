import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import canUseDom from "../../utils/canUseDom";

const Portal = ({ children }) => {
    if (canUseDom) {
        return <CreatePortal>{children}</CreatePortal>;
    }
    return null;
};

const CreatePortal = ({ children }) => {
    const target = useRef(document.createElement("div"));

    useEffect(() => {
        const el = target.current;
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        };
    }, []);

    return createPortal(children, target.current);
};

export default Portal;
