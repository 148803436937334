import React from "react";

const Input = ({
  type = "text",
  className = "form-control",
  setValue = null,
  value = "",
  ...rest
}) => {
  return (
    <input
      type={type}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value);
        }
      }}
      value={value}
      className={className}
      {...rest}
    />
  );
};

export const NumberInput = ({
  name = "",
  type,
  className = "form-control",
  setValue = null,
  value = "",
  ...rest
}) => {
  const onKeyPress = (event) => {
    if (!(event.charCode >= 48 && event.charCode <= 57)) {
      event.preventDefault();
      return;
    }
  };

  return (
    <input
      type="tel"
      name={name}
      className={className}
      onKeyPress={onKeyPress}
      value={value}
      onChange={(e) => {
        if (setValue) {
          setValue(e.target.value.replace(new RegExp("[^0-9]", "g"), ""));
        }
      }}
      {...rest}
    />
  );
};

export const RefCodeIp = ({
  name = "",
  type,
  className = "form-control",
  setValue = null,
  value = "",
  ...rest
}) => {
  return (
    <input
      type="text"
      name={name}
      className={className}
      value={value}
      onChange={(e) => {
        if (setValue) {
          setValue(
            e.target.value
              .toUpperCase()
              .replace(new RegExp("[^A-Z]", "g"), "")
              .toUpperCase()
          );
        }
      }}
      {...rest}
    />
  );
};

export default Input;
