import React from "react";
import { X } from "react-feather";

const ModalHeader = ({ title = "", desc = "", close = null }) => {
  return (
    <div className="modal-header">
      {close !== null && (
        <button className="btn btn-modal-close" onClick={close}>
          <X size={20} />
        </button>
      )}
      <div>
        <h3 className="m-0 f-18">{title}</h3>
        <div>{desc}</div>
      </div>
    </div>
  );
};

export default ModalHeader;
