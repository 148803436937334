import { isPlainObject } from ".";

const isEmpty = (value) =>
    value === undefined || value === null || value === "";

const keysToQuery = (keys) => {
    let q = [];
    Object.keys(keys).forEach((key) => {
        if (!isEmpty(keys[key])) {
            q.push(`${key}=${keys[key]}`);
        }
    });

    return q.length > 0 ? "?" + q.join("&") : "";
};

export const getFilters = (filterQuery) => {
    const f = filterQuery ? JSON.parse(filterQuery) : {};
    return isPlainObject(f) ? f : {}
}


export default keysToQuery;
