import React from "react";
import { useHistory } from "react-router-dom";
import { setErrors } from "../../../hooks/form/formReducer";
import useFormReducer from "../../../hooks/form/useFormReducer";
import useScrollToError from "../../../hooks/form/useScrollToError";
import useQuery from "../../../hooks/useQuery";
import keysToQuery from "../../../utils/keysToQuery";
import GoBack from "../../common/GoBack";
import FieldArray from "../../form/FieldArray";
import { FormProvider } from "../../form/Form";
import SubmitBtn from "../../form/SubmitBtn";
import validateAll from "../../form/validateAll";
import { form1, form2, formKeys } from "./costform";

const SheetForm = ({ initValues = {}, apartmentId }) => {
    const history = useHistory();
    const form = useFormReducer({ values: initValues });
    const [{ values }, dispatch] = form;

    const scrolltoError = useScrollToError();

    const onSubmit = (e) => {
        e.preventDefault();
        const allIds = [...form1, ...form2];
        const errors = validateAll(allIds, values);
        if (Object.keys(errors).length > 0) {
            dispatch(setErrors(errors));
            scrolltoError();
            return false;
        }
        const q = keysToQuery(values);
        history.push(`/cost-sheet/${apartmentId}${q}`);
    };

    return (
        <section className="pt-3 pb-3 bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <GoBack />
                        <h4 className="mb-3 pt-2 text-center">COST SHEET FORM</h4>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="pb-3"
                                    autoComplete="off"
                                    noValidate
                                    onSubmit={onSubmit}
                                >
                                    <FormProvider form={form}>
                                        <div className="f-500 p-2 mb-3 text-center bg-light text-uppercase">
                                            Customer Details
                                        </div>
                                        <div className="form-row">
                                            <FieldArray allIds={form1} />
                                        </div>
                                        <div className="f-500 p-2 mb-3 text-center bg-light text-uppercase">
                                            Apartment Price Details
                                        </div>
                                        <div className="form-row">
                                            <FieldArray allIds={form2} />
                                        </div>
                                        <SubmitBtn className="btn btn-theme btn-block">
                                            Submit
                                        </SubmitBtn>
                                    </FormProvider>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const CostSheetForm = () => {
    const query = useQuery();
    const apartmentId = query.get("id");

    const initValues = {
        [formKeys.plc]: query.get("plc"),
        [formKeys.frc]: query.get("floor_rise_rate"),
        [formKeys.noOfCar]: query.get("no_of_carpark"),
        [formKeys.baseRate]: query.get("basic_rate"),
        [formKeys.amenities]: query.get("amenities_charges"),
    };

    return <SheetForm initValues={initValues} apartmentId={apartmentId} />;
};

export default CostSheetForm;
