import React, { useEffect } from "react";
import { formcontext } from "../../hooks/form/useForm";
import useFormReducer from "../../hooks/form/useFormReducer";
import useSubmit from "../../hooks/http/useSubmit";
import {
    setSubmiting,
    setErrors,
    resetForm,
} from "../../hooks/form/formReducer";
import useScrollToError from "../../hooks/form/useScrollToError";

const Form = ({
    children,
    config = {},
    className = "",
    handleSuccess,
    validateForm = () => false,
    formatData = (vals) => vals,
    resetOnSuccess = true,
    errorScroll = false,
    initValues = {},
}) => {
    const scrolltoError = useScrollToError();
    const value = useFormReducer({ values: initValues });
    const [state, dispatch] = value;

    const [fetching, submit] = useSubmit({
        success: (data) => {
            if (resetOnSuccess) {
                dispatch(resetForm());
            }
            if (typeof handleSuccess === "function") {
                handleSuccess(data, state.values);
            }
        },
    });

    useEffect(() => {
        dispatch(setSubmiting(fetching));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetching]);

    const onSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(state.values);
        if (Object.keys(errors).length > 0) {
            dispatch(setErrors(errors));
            if (errorScroll) {
                scrolltoError();
            }
            return;
        }
        const formData = formatData(state.values);
        if (config.url) {
            submit({
                method: "POST",
                ...config,
                data: formData,
            });
        }
    };

    return (
        <FormProvider form={value}>
            <form noValidate autoComplete="off" onSubmit={onSubmit} className={className}>
                {children}
            </form>
        </FormProvider>
    );
};

export const FormProvider = ({ form, children }) => {
    return <formcontext.Provider value={form}>{children}</formcontext.Provider>;
};

export default Form;
