import React, { useCallback, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { isArray } from "validate.js";
import { useMasterValues } from "./MasterData";

const ProjectContext = createContext({});
export const useProjectValues = () => {
  return useContext(ProjectContext).value;
};

const ProjectData = ({ children }) => {
  const localProject = JSON.parse(localStorage.getItem("selectedProject"));
  const [projectsFetching, master] = useMasterValues();

  const { project } = master;
  const defaultProject = localProject
    ? localProject
    : isArray(project)
    ? project[0]
    : "";
  const [crm_project, setProject] = useState(defaultProject);

  const updateProject = useCallback((project) => {
    localStorage.setItem("selectedProject", JSON.stringify(project));
    setProject(project);
  }, []);

  useEffect(() => {
    if (!crm_project) {
      if (isArray(project)) {
        updateProject(project[0]);
      }
    }
  }, [crm_project, project, updateProject]);

  return (
    <ProjectContext.Provider
      value={{
        value: { crm_project, updateProject, projectsFetching },
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectData;
