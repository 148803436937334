import React from "react";

const Switchbutton = ({ setValue, checked = false }) => {
    return (
        <label className={`switch switch-${checked ? "active" : "inactive"}`}>
            <input
                type="checkbox"
                className="control-input"
                checked={checked}
                onChange={(e) => setValue(e.target.checked)}
            />
            <div></div>
        </label>
    );
};

export default Switchbutton;
