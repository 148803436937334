import React, { useState } from "react";
import logo from "../../images/logo.png";
import { NavLink } from "react-router-dom";
import { Search } from "react-feather";
import UserMenu from "./UserMenu";
import useUser from "../../hooks/user/useUser";

const Header = ({ headerRight = null }) => {
    const { token } = useUser()[0];

    return (
        <div className="header">
            <div className="d-flex align-items-center">
                <NavLink to="/">
                    <img className="logo" src={logo} alt="" />
                </NavLink>
                {token && (
                    <div className="ml-3 mr-3 d-none d-md-block">
                        <SearchLeads />
                    </div>
                )}
            </div>
            {token && (
                <div className="d-flex align-items-stretch">
                    <ul className="header-right">{headerRight}</ul>
                    <UserMenu />
                </div>
            )}
        </div>
    );
};

export const SearchLeads = ({ isMobile = false, close }) => {
    const [value, setValue] = useState();

    return (
        <>
            <div className="d-flex border align-items-stretch rounded-pill pl-3 pr-3">
                <input
                    className="form-control border-0"
                    type="search"
                    placeholder="Search"
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                />
                {!isMobile &&
                    <NavLink

                        to={{ pathname: `search`, search: `?q=${value}` }}
                        className="btn p-1"
                    >
                        <Search strokeWidth={2} size={22} />
                    </NavLink>
                }
            </div>

            {isMobile &&
                <div className="d-flex justify-content-end mt-4">

                    <NavLink
                        onClick={close}
                        to={{ pathname: `search`, search: `?q=${value}` }}
                        className="btn btn-theme">
                        Submit
                    </NavLink>


                </div>
            }

        </>
    );
};

export default Header;
