import React from "react";
import Field from "./Field";

const FieldArray = ({ allIds, commonProps = {} }) => {
    return allIds.map((item, idx) => (
        <Field {...commonProps} {...item} key={item.name + "--" + idx} />
    ));
};

export default FieldArray;
