import React from "react";
import CheckBox from "./CheckBox";

const RadioGroup = ({ options = [], name, value = null, setValue, isDisabled=false }) => {
    return (
        <div className="d-flex flex-wrap">
            {options.map((opt, idx) => (
                <div className="mr-4" key={idx}>
                    <CheckBox
                        title={opt.title}
                        name={name}
                        value={value === opt.value}
                        setValue={(isChecked) => {
                            if (isChecked) {
                                setValue(opt.value);
                            } else {
                                setValue(null);
                            }
                        }}
                        listIndex={idx + 1}
                        disabled={isDisabled}
                    />
                </div>
            ))}
        </div>
    );
};

export const CheckGroup = ({ options = [], name, value = [], setValue }) => {
    return (
        <div className="d-flex flex-wrap">
            {options.map((opt, idx) => (
                <div className="mr-4" key={idx}>
                    <CheckBox
                        title={opt.title}
                        name={name}
                        value={
                            Array.isArray(value) && value.includes(opt.value)
                        }
                        setValue={(isChecked) => {
                            if (Array.isArray(value)) {
                                if (isChecked) {
                                    setValue([...value, opt.value]);
                                } else {
                                    setValue(
                                        value.filter((x) => x !== opt.value)
                                    );
                                }
                            }
                        }}
                        listIndex={idx + 1}
                    />
                </div>
            ))}
        </div>
    );
};

export const CheckGroupInline = ({ options = [], name, value = [], setValue }) => {
    console.log(options);
    return <div className="ml-1 row">
        {options.map((opt, idx) => (
            <div className="col-3" key={idx}>
                <CheckBox
                    title={opt.title}
                    name={name}
                    value={
                        Array.isArray(value) && value.includes(opt.value)
                    }
                    setValue={(isChecked) => {
                        if (Array.isArray(value)) {
                            if (isChecked) {
                                setValue([...value, opt.value]);
                            } else {
                                setValue(
                                    value.filter((x) => x !== opt.value)
                                );
                            }
                        }
                    }}
                    listIndex={idx + 1}
                />
            </div>
        ))}
    </div>
};

export default RadioGroup;
