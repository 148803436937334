import React, { useState, useCallback } from "react";
import urls from "../../apiService/urls";
import useSubmit from "../../hooks/http/useSubmit";
import useInterval from "../../hooks/useInterval";
import useUser from "../../hooks/user/useUser";
import CheckBox from "../form/CheckBox";
import FieldCon from "../form/FieldCon";
import Input from "../form/Input";
import SubmitBtn from "../form/SubmitBtn";

const Login = () => {
    const [form1, set] = useState({});

    const setForm1 = useCallback((upt = {}) => {
        set((val) => ({ ...val, ...upt }));
    }, []);

    if (form1 && form1.username) {
        return <Form2 {...form1} setForm1={setForm1} />;
    }
    return <Form1 setForm1={setForm1} />;
};

export const Form1 = ({ setForm1 }) => {
    const { update } = useUser()[1];
    const [username, setUsername] = useState("");
    const [pwd, setPwd] = useState("");
    const [err, setErr] = useState("");
    const [isOtp, setIsOtp] = useState(false);

    const [fetching, submit] = useSubmit({
        success: (data) => {
            if (data.token) {
                update(data);
            } else {
                setForm1({ username: data.username, isOtp: true });
            }
        },
    });

    const onClick = () => {
        if (isOtp) {
            submit({
                url: urls.resendOtp,
                method: "POST",
                data: {
                    username,
                },
            });
        } else {
            submit({
                url: urls.login,
                method: "POST",
                data: {
                    username,
                    password: pwd,
                    client: Math.random() * 10000,
                },
            });
        }
    };

    const onUserChange = useCallback(
        (val) => {
            if (err && val) {
                setErr("");
            }
            setUsername(val);
        },
        [err]
    );

    return (
        <div>
            <FieldCon label="Mobile Number" err={err} required>
                <Input
                    value={username}
                    setValue={onUserChange}
                    type="tel"
                    placeholder="Enter your mobile number"
                />
            </FieldCon>
            <FieldCon label={"Password"} required>
                <Input
                    placeholder={`Enter password`}
                    value={pwd}
                    type="password"
                    setValue={(val) => setPwd(val)}
                    disabled={isOtp}
                />
            </FieldCon>
            <FieldCon>
                <CheckBox
                    value={isOtp}
                    setValue={(val) => setIsOtp(val)}
                    title="Sign in with OTP"
                    name="isOtp"
                />
            </FieldCon>
            <SubmitBtn
                disabled={isOtp ? !username : !(username && pwd)}
                onClick={onClick}
                className="btn btn-theme btn-block"
                fetching={fetching}
            >
                {isOtp ? "Send OTP" : "Sign In"}
            </SubmitBtn>
        </div>
    );
};

export const Form2 = ({ username, isOtp }) => {
    const { update } = useUser()[1];
    const [value, set] = useState("");

    const [fetching, submit] = useSubmit({
        success: (data) => {
            update(data);
        },
    });

    const onClick = useCallback(() => {
        if (username && value) {
            submit({
                url: urls.login,
                method: "POST",
                data: {
                    username,
                    otp: value,
                    client: Math.random() * 10000,
                },
            });
        }
    }, [submit, username, value]);

    return (
        <div>
            <FieldCon label="Mobile Number" required>
                <Input value={username} type="text" readOnly />
            </FieldCon>
            <FieldCon label={isOtp ? "OTP" : "Password"} required>
                <Input
                    placeholder={`Enter ${isOtp ? "OTP" : "password"}`}
                    value={value}
                    type="password"
                    setValue={(val) => set(val)}
                />
            </FieldCon>
            <SubmitBtn
                disabled={!(username && value)}
                fetching={fetching}
                onClick={onClick}
                className="btn btn-theme btn-block mb-3"
            >
                Submit
            </SubmitBtn>
            {isOtp && (
                <div className="text-center">
                    <ResendOtp username={username} />
                </div>
            )}
        </div>
    );
};

export const ResendOtp = ({ username }) => {
    const [count, setCount] = useState(30);
    const [fetching, submit] = useSubmit({
        success: () => {
            setCount(30);
        },
    });

    useInterval(
        () => {
            setCount((x) => (x ? x - 1 : 0));
        },
        count ? 1000 : null
    );

    const onClick = () => {
        if (username) {
            submit({
                url: urls.resendOtp,
                method: "POST",
                data: {
                    username,
                },
            });
        }
    };

    if (count) {
        return <Counter count={count} />;
    }
    return (
        <button
            block
            onClick={onClick}
            disabled={fetching}
            textDecor="underline"
            className="btn p-0 f-14 text-underline"
        >
            {fetching ? "Resending..." : "Not received it yet? Resend OTP"}
        </button>
    );
};

const Counter = ({ count = 0 }) => {
    return <div className="f-14 text-center">Wait for {count}s</div>;
};

export default Login;
