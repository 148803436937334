import validate, { isDate } from "validate.js";
import dayjs from "dayjs";
import formTypes from "./formTypes";

const validateAll = (allIds = [], values) => {
  let errors = {};
  allIds.forEach((cur) => {
    const err = isValid(cur, values[cur.name]);
    if (err) {
      errors[cur.name] = err;
    }
  }, {});
  return errors;
};

const isValid = (cur, value) => {
  const required = !!cur.required;
  switch (cur.type) {
    case formTypes.password:
      return pwdValid(value, required);
    case formTypes.email:
      return emailValid(value, required);
    case formTypes.url:
      return urlValid(value, required);
    case formTypes.tel:
      return phoneValid(value, required);
    case formTypes.date:
    case formTypes.datetime:
      return dateValid(value, required);
    case formTypes.time:
      return dateValid(value, required, "Time");
    case formTypes.year:
      return dateValid(value, required, "Year");
    case formTypes.daterange:
      return dateRangeValid(value, required);
    case formTypes.timerange:
      return dateRangeValid(value, required, "time");
    case formTypes.emailTag:
      return emailTagValid(value, required);
    case formTypes.text:
    case formTypes.search:
    case formTypes.number:
    case formTypes.select:
    case formTypes.textarea:
    default:
      return validate.single(
        value,
        required ? { presence: { allowEmpty: false } } : {}
      )
        ? "This field is required"
        : "";
  }
};

export const emailTagValid = (value, required) => {
  if (required) {
    return validate.isArray(value) && value.length > 0
      ? ""
      : `This field is required`;
  }
};

export const dateValid = (val = "", required = false, type = "Date") => {
  if (val || required) {
    return isDate(val) ? "" : `${type} is not valid`;
  }
  return "";
};

export const pwdValid = (val = "", required = false) => {
  if (val || required) {
    return validate.single(val, { length: { minimum: 6 } })
      ? "Password must be atleast 6 characters"
      : "";
  }
  return "";
};

export const emailValid = (val = "", required = false) => {
  if (val || required) {
    return validate.single(val, { email: true }) ? "Invalid email" : "";
  }
  return "";
};

export const urlValid = (val = "", required = false) => {
  if (val || required) {
    return validate.single(val, { url: true }) ? "Invalid url" : "";
  }
  return "";
};

export const phoneValid = (val = "", required = false) => {
  if (val || required) {
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!re.test(val)) {
      return "Invalid Phone number";
    }
    return "";
  }
  return "";
};

const isObj = (obj) =>
  Object.prototype.toString.call(obj) === "[object Object]";

const dateRangeValid = (val, required = false, rangeType = "date") => {
  if (val || required) {
    if (!isObj(val)) {
      return "This feild is required";
    }
    if (!isDate(val.from)) {
      return `From ${rangeType} is not valid`;
    }
    if (!isDate(val.to)) {
      return `To ${rangeType} is not valid`;
    }
    if (!dayjs(val.to).isAfter(val.from)) {
      return `To ${rangeType} cannot be before From ${rangeType}`;
    }
    return "";
  }
  return "";
};

export default validateAll;
