import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import BrouchureLinks from "../../components/layout/BrouchureLinks";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/sales-coordinator";

export const paths = {
    cab: "/cab-details",
    conversion: "/booking-conversion",
    avail: "/availability-and-cost-sheet",
    reports: "/reports",
    blocking: "/blocking",
    search:"/search",
};

export const landing = `${pathName}${paths.cab}`;

export const pages = {
    landing,
    conversion: `${pathName}${paths.conversion}`,
    avail: `${pathName}${paths.avail}`,
    reports: `${pathName}${paths.reports}`,
    blocking: `${pathName}${paths.blocking}`,
};

export const menu = [
    {
        title: "Cab Details",
        to: landing,
    },
    {
        title: "Booking Conversion",
        to: pages.conversion,
    },
    {
        title: "Availability and Cost Sheet",
        to: pages.avail,
    },
    {
        title: "Blocking",
        to: pages.blocking,
    },
    {
        title: "Reports",
        to: pages.reports,
    },
];

export const routes = [
    {
        path: paths.cab,
        component: lazy(() => import("./CabDetails")),
        exact: true,
    },
    {
        path: `${paths.cab}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.conversion,
        component: lazy(() => import("./Conversion")),
        exact: true,
    },
    {
        path: `${paths.conversion}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.conversion}/convert/:leadId`,
        component: lazy(() => import("../sales-manager/Convert")),
    },
    {
        path: paths.blocking,
        component: lazy(() => import("./Blocking")),
        exact: true,
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
        exact: true,
    },
    {
        path: paths.avail,
        component: lazy(() => import("../sales-manager/Availability")),
        exact: true,
    },
    {
        path: `${paths.avail}/apartment/:apartmentId`,
        component: lazy(() => import("../sales-manager/Apartment")),
    },
    {
        path: `${paths.avail}/tower-wise-availability`,
        component: lazy(() => import("../sales-manager/TowerAvailability")),
    },
    {
        path: `${paths.avail}/project-cost-sheet`,
        component: lazy(() => import("../sales-manager/ProjectCostSheet")),
    },
    {
        path: `${paths.avail}/tower-reports`,
        component: lazy(() => import("../sales-manager/TowerReport")),
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
];

export const allowRoles = [8];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Sales Co-ordinator"
            routes={routes}
            allowRoles={allowRoles}
            menu={menu}
            landing={landing}
            headerRight={<BrouchureLinks />}
        />
    </AuthRoute>
);
