import React from "react";
import Loader from "./Loader";

const SubmitBtn = ({ className, children, fetching, disabled, ...rest }) => {
    return (
        <button
            type="submit"
            disabled={fetching || disabled}
            className={className}
            {...rest}
        >
            {fetching ? <Loader size={16} fill="#fff" /> : children}
        </button>
    );
};

export default SubmitBtn;
