/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ChevronDown } from "react-feather";
import { isArray } from "../../utils";
import { useMasterValues } from "../common/MasterData";
import { HeaderMenuItem } from "./HeaderMenu";
import AttendanceSwitch from "./Attendance";
import { loadLocalState } from "../../utils/localstorage";

const BrouchureLinks = () => {
    const [fetching, data] = useMasterValues();
    const projects = isArray(data?.project) ? data.project : [];

    if (fetching) {
        return null;
    }
    const brochures = projects.filter((x) => !!x.brochure);
    const websites = projects.filter((x) => !!x.website_link);
    const walkthrough = projects.filter((x) => !!x.youtube_url);    
    const loggedinfo = loadLocalState();

    return (
        <>
            {brochures.length > 0 && (
                <HeaderMenuItem extraCls="drop-menu-con">
                    Brochures <ChevronDown size={14} />
                    <ul className="drop-menu">
                        {brochures.map((x) => (
                            <li key={x.id}>
                                <a
                                    className="text-body"
                                    href={x.brochure}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {x.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </HeaderMenuItem>
            )}
            {websites.length > 0 && (
                <HeaderMenuItem extraCls="drop-menu-con">
                    Websites <ChevronDown size={14} />
                    <ul className="drop-menu">
                        {websites.map((x) => (
                            <li key={x.id}>
                                <a
                                    className="text-body"
                                    href={x.website_link}
                                    download={x.title}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {x.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </HeaderMenuItem>
            )}
            {websites.length > 0 && (
                <HeaderMenuItem extraCls="drop-menu-con">
                    Project Clipping <ChevronDown size={14} />
                    <ul className="drop-menu">
                        {websites.map((x) => (
                            <li key={x.id}>
                                <a
                                    className="text-body"
                                    href={`${x.website_link}#gallery`}
                                    download={x.title}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {x.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </HeaderMenuItem>
            )}
            {walkthrough.length > 0 && (
                <HeaderMenuItem extraCls="drop-menu-con">
                    Walkthrough <ChevronDown size={14} />
                    <ul className="drop-menu">
                        {websites.map((x) => (
                            <li key={x.id}>
                                <a
                                    className="text-body"
                                    href={`${x.youtube_url}`}
                                    download={x.title}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {x.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </HeaderMenuItem>
            )}
            {websites.length > 0 && (loggedinfo.role.id==3 || loggedinfo.role.id==4)  && (
                <HeaderMenuItem extraCls="drop-menu-con attendance-switch">
                    <span class="attendance-text">Accept Leads</span><AttendanceSwitch></AttendanceSwitch>
                </HeaderMenuItem>
            )}
        </>
    );
};

export default BrouchureLinks;
