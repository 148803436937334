import React from "react";

const TextArea = ({
    className = "form-control",
    setValue = null,
    value = "",
    ...rest
}) => {
    return (
        <textarea
            onChange={(e) => {
                if (setValue) {
                    setValue(e.target.value);
                }
            }}
            className={className}
            value={value}
            {...rest}
        />
    );
};

export default TextArea;
