import { landing as marktLanding } from "./marketing-manager";
import { landing as marktLandingExective } from "./marketing-manager-executive";
import { landing as telLanding } from "./tele-caller";
import { landing as telTeamLanding } from "./tele-caller-team-lead";
import { landing as salesLanding } from "./sales-manager";
import { landing as salesTeamLanding } from "./sales-manager-team-lead";
import { landing as officeLanding } from "./front-office-executive";
import { landing as salesGMLanding } from "./sales-general-manager";
import { landing as salesCOLanding } from "./sales-coordinator";
import { landing as crmExecutives } from "./crm-executives";
import { landing as crmHead } from "./crm-head";
import { landing as legalAdvisor } from "./legal-advisor";
import { landing as FM } from "./fm";
import { landing as auditor } from "./auditor";

export default {
    1: telLanding,
    2: telTeamLanding,
    5: marktLanding,
    3: salesLanding,
    4: salesTeamLanding,
    6: officeLanding,
    7: salesGMLanding,
    8: salesCOLanding,
    10: marktLandingExective,
    11: crmExecutives,
    12: crmHead,
    13: legalAdvisor,
    14: FM,
    18: crmExecutives,
    19: crmExecutives,
    20: crmExecutives,
    21: auditor,
};
