import React from "react";

const FileInput = ({ setValue, accept = "", multiple = false }) => {
    return (
        <div>
            <input
                type="file"
                onChange={(e) => {
                    const { files } = e.target;
                    if (files && files.length > 0) {
                        setValue(Array.from(e.target.files));
                    } else {
                        setValue([]);
                    }
                }}
                accept={accept}
                multiple={multiple}
            />
        </div>
    );
};

export default FileInput;
