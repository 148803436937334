import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import React from "react";
import { emailValid } from "./validateAll";

const EmailTagIp = ({
  setValue = null,
  placeholder = " ",
  value = [],
  ...ipProps
}) => {
  return (
    <ReactTagInput
      tags={value || []}
      placeholder={placeholder}
      onChange={(e) => setValue(e)}
      validator={(value) => {
        const isEmail = emailValid(value);
        if (isEmail) {
          alert(isEmail);
          return;
        }
        return value;
      }}
      {...ipProps}
    />
  );
};

export default EmailTagIp;
