import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import SalesReport from "../../components/layout/SalesReport";

export const pathName = "/front-office-executive";

export const paths = {
    customerForm: "/customer-form",
    reports: "/reports",
    search: "/search"
};

export const landing = `${pathName}${paths.customerForm}`;

export const allowRoles = [6];

export const pages = {
    landing,
};

export const menu = [
    {
        title: "Enquiry",
        to: `${pathName}${paths.customerForm}`,
    },
    {
        title: "Reports",
        to: `${pathName}${paths.reports}`,
    },
];


export const routes = [
    {
        path: paths.customerForm,
        exact: true,
        component: lazy(() => import("./CustomerForm")),
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Telecaller"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<SalesReport />}
        />
    </AuthRoute>
);
