export default {
  text: "text",
  search: "search",
  url: "url",
  email: "email",
  password: "password",
  tel: "tel",
  number: "number",
  textarea: "textarea",
  checkbox: "checkbox",
  radiogroup: "radiogroup",
  checkgroup: "checkgroup",
  select: "select",
  normalSelect: "normalSelect",
  date: "date",
  datetime: "datetime",
  daterange: "daterange",
  year: "year",
  time: "time",
  timerange: "timerange",
  file: "file",
  emailTag: "emailTag",
  htmlEditor: "htmlEditor",
  monthyear:"monthyear",
  checkgroupinline: "checkgroupinline",
};
