export const userTyps = {
    update: "user/update",
    clear: "user/clear",
};

export const init = {};

export const updateUser = (payload = {}) => {
    return {
        type: userTyps.update,
        payload,
    };
};

export const clearUser = () => {
    return {
        type: userTyps.clear,
    };
};

export default (state = {}, action) => {
    switch (action.type) {
        case userTyps.update:
            return {
                ...state,
                ...action.payload,
            };
        case userTyps.clear:
            return {};
        default:
            return state;
    }
};
