import React, { Suspense } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Layout from "../components/layout/Layout";
import useUser from "../hooks/user/useUser";
import NotFound from "./NotFound";

const renderRoutes = (routes = [], rel = "") =>
    routes.map((r, idx) => {
        return <Route {...r} path={`${rel}${r.path}`} key={idx} />;
    });

export const RoleRoute = ({ allowRoles = [], landing, children = null }) => {
    const { role } = useUser()[0];

    const userRole = role && role.id ? role.id : null;


    if (!allowRoles.includes(userRole)) {
        return <Redirect to={landing ? landing : "/logout"} />;
    }

    return children;
};

const RoleRoutes = ({
    title,
    routes,
    allowRoles = [],
    menu = [],
    landing,
    headerRight,
}) => {
    let { path } = useRouteMatch();
    return (
        <RoleRoute allowRoles={allowRoles} landing={landing}>
            <Layout title={title} menu={menu} headerRight={headerRight}>
                <Suspense
                    fallback={<div className="text-center p-5">Loading...</div>}
                >
                    <Switch>
                        {renderRoutes(routes, path)}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            </Layout>
        </RoleRoute>
    );
};

export default RoleRoutes;
