import React from "react";

export const CheckBox = ({
    name = "",
    setValue = null,
    listIndex = "single",
    value = false,
    title = "",
    className = "",
    ...restProps
}) => {
    const ipName = `${name}-${listIndex}`;

    return (
        <div className={`d-flex align-item-center ${className}`}>
            <div className="multiple-checkbox">
                <input
                    {...restProps}
                    id={ipName}
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        if (setValue) {
                            setValue(isChecked);
                        }
                    }}
                />
                <label
                    className="radio-label d-flex align-items-center f-400"
                    htmlFor={ipName}
                >
                    {title}
                </label>
            </div>
        </div>
    );
};
export default CheckBox;
