import { useState, useCallback, useEffect } from "react";
import canUseDom from "../../utils/canUseDom";

const useScrollToError = () => {
    const [scrolling, setScroll] = useState(false);

    useEffect(() => {
        if (scrolling && canUseDom) {
            const el = document.querySelector(".errMsg");
            if (el && el.scrollIntoView) {
                el.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });
            }
            setScroll(false);
        }
    }, [scrolling]);

    return useCallback(() => {
        if (!scrolling) {
            setScroll(true);
        }
    }, [scrolling]);
};

export default useScrollToError;
