import React from "react";
import { ChevronDown } from "react-feather";
import useUser from "../../hooks/user/useUser";
import { Link } from "react-router-dom";
import Modal from "../modal/Modal";
import ModalBody from "../modal/ModalBody";
import { SearchLeads } from "./Header";
import useToggle from "../../hooks/useToggle";
import ModalHeader from "../modal/ModalHeader";

const UserMenu = () => {
    const { token, user } = useUser()[0];
    const { toggle, onFalse, onTrue } = useToggle();

    if (!token) {
        return null;
    }

    return (
        <div className="hello-block text-center ml-4 drop-menu-con">
            <small className="f-12">Your Account</small>
            <div className="font-weight-bold f-14">
                {user && user.first_name ? user.first_name : "User"}{" "}
                <ChevronDown size={14} />
            </div>
            <ul className="drop-menu">
                <li className="d-block d-sm-none" onClick={() => onTrue()}>
                    Search Leads
                </li>
                <li>
                    <Link className="text-body" to="/change-password">
                        Change Password
                    </Link>
                </li>
                <li>
                    <Link className="text-body" to="/logout">
                        Logout
                    </Link>
                </li>
            </ul>


            <Modal isOpen={toggle} close={onFalse}>
                <ModalHeader title="Search" close={onFalse} />
                <ModalBody>
                    <SearchLeads isMobile={true} close={onFalse} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UserMenu;
