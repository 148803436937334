import React, { useEffect, useState } from "react";
import Switchbutton from "./Switchbutton";
import { AttendanceInfo } from "../sales-manager/attendance/AttendanceInfo";
import { loadLocalState, saveLocalState } from "../../utils/localstorage";
import useSubmit from "../../hooks/http/useSubmit";
import { salesManagerUrls } from "../../apiService/urls";

const tab = 1;
export const AttendanceSwitch = () => {
  const loggedinfo = loadLocalState();
  //const checkActive = (loggedinfo.attendance == 1 || loggedinfo.current_project==undefined)?true:false;
  const checkActive = loggedinfo.attendance !== -1 ? true : false;

  const initPop = loggedinfo.attendance == undefined ? true : false;
  const project = loggedinfo.current_project;
  const [isChecked, setIsChecked] = useState(checkActive);
  const [openModal, setModal] = useState(initPop);
  const [handleSwitch, setHandleSwitch] = useState(false);

  const [fetching, submit] = useSubmit({
    success: () => {
      loggedinfo.attendance = 0;
      saveLocalState(loggedinfo);
    },
  });

  const handleSwitchToggle = (isChecked) => {
    setHandleSwitch(true);
    setIsChecked(true);
    /* setIsChecked(isChecked);
    if(!isChecked) {
      let formData = {}
      formData.type = 2;
      formData.project = loadLocalState().current_project;
      submit({
        url: `${salesManagerUrls.attendance}`,
        method: "POST",
        data: formData,
      });
    } */
    setModal(true);
  };

  const closeModal = (checked) => {
    setIsChecked(checked);
    setModal(false);
    setHandleSwitch(false);
  };

  if (handleSwitch) {
    return (
      <>
        <Switchbutton setValue={handleSwitchToggle} checked={isChecked} />
        <AttendanceInfo
          title={"Attendance Details"}
          openModal={openModal}
          onClose={closeModal}
        />
      </>
    );
  }

  return (
    <>
      <Switchbutton setValue={handleSwitchToggle} checked={isChecked} />
      {initPop && (
        <AttendanceInfo
          title={"Attendance Details"}
          initpop={true}
          openModal={openModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default AttendanceSwitch;
