import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import BrouchureLinks from "../../components/layout/BrouchureLinks";
export const pathName = "/sales-general-manager";

export const paths = {
    dashboard: "/dashboard",
    target: "/target",
    booking: "/booking-approval",
    DigitalBookingApproval: "/booking-digital-approval",
    team: "/team-details",
    avail: "/availability-and-cost-sheet",
    leadApproval: "/lead-approval",
    reports: "/reports",
    lead_reports: "/lead-reports",
    search:"/search",
    CancelledBooking:"/cancelled-booking",
    crm:"/crm",

};

export const landing = `${pathName}${paths.dashboard}`;

export const pages = {
    landing,
    target: `${pathName}${paths.target}`,
    booking: `${pathName}${paths.booking}`,
    DigitalBookingApproval: `${pathName}${paths.DigitalBookingApproval}`,
    team: `${pathName}${paths.team}`,
    avail: `${pathName}${paths.avail}`,
    leadApproval: `${pathName}${paths.leadApproval}`,
    reports: `${pathName}${paths.reports}`,
    lead_reports: `${pathName}${paths.lead_reports}`,
    CancelledBooking: `${pathName}${paths.CancelledBooking}`,
    Crm: `${pathName}${paths.crm}`,
};

export const menu = [
    {
        title: "Dashboard",
        to: landing,
    },
    {
        title: "Target",
        to: pages.target,
    },
    {
        title: "Availability and Cost Sheet",
        to: pages.avail,
    },
    {
        title: "Booking Approval",
        to: pages.booking,
    },
    
    {
        title: "Sales Team",
        to: pages.team,
    },
    {
        title: "Dead Lead Approval",
        to: pages.leadApproval,
    },
    {
        title: "Reports",
        to: pages.reports,
    },
    {
        title: "New Reports",
        to: pages.lead_reports,
    },
    {
        title: "Cancellation Approval",
        to: pages.CancelledBooking,
    },
    // {
    //     title: "Payment Received",
    //     to: pages.Crm,
    // },
    {
        title: "Digital Approval",
        to: pages.DigitalBookingApproval,
    },
];

export const routes = [
    {
        path: paths.dashboard,
        component: lazy(() => import("./Dashboard")),
    },
    {
        path: paths.target,
        component: lazy(() => import("./Target")),
    },
    {
        path: paths.avail,
        component: lazy(() => import("../sales-manager/Availability")),
        exact: true,
    },
    {
        path: `${paths.avail}/apartment/:apartmentId`,
        component: lazy(() => import("../sales-manager/Apartment")),
    },
    {
        path: `${paths.avail}/tower-wise-availability`,
        component: lazy(() => import("../sales-manager/TowerAvailability")),
    },
    {
        path: `${paths.avail}/project-cost-sheet`,
        component: lazy(() => import("../sales-manager/ProjectCostSheet")),
    },
    {
        path: `${paths.avail}/tower-reports`,
        component: lazy(() => import("../sales-manager/TowerReport")),
    },
    {
        path: paths.booking,
        component: lazy(() => import("./Booking")),
        exact: true,
    },
    
    {
        path: paths.team,
        component: lazy(() => import("./SalesTeam")),
        exact: true,
    },
    {
        path: paths.DigitalBookingApproval,
        component: lazy(() => import("./digitalapprove")),
        exact: true,
    },
    {
        path: `${paths.team}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: `${paths.DigitalBookingApproval}/view_booking/:status/:customerId`,
        exact: true,
        component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
    },
    {
        path: `${paths.crm}/payment/view/:customerId/payment-received-from-customer`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
          )
        ),
    },

    {
        path: `${paths.crm}/view/:customerId/payment-received-receipt/:receiptId`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
          )
        ),
      },

     
    // {
    //     path: `${paths.crm}/view/:customerId/payment-received-from-customer`,
    //     exact: true,
    //     component: lazy(() =>
    //       import(
    //         "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
    //       )
    //     ),
    //   },
    // {
    //     path: `${paths.DigitalBookingApproval}/view_rejected_booking/:customerId`,
    //     exact: true,
    //     component: lazy(() => import("./BookingDigitalApproval/RejectedBookingDetail")),
    // },
    // {
    //     path: `${paths.cleadApproval() => import("./CRM/CustomerMaster/ApprovedBookingDetail")),
    // },
    // {
    //     path: `${paths.crm}/view_rejected_booking/:customerId`,
    //     exact: true,
    //     component: lazy(() => import("./CRM/CustomerMaster/RejectedBookingDetail")),
    // },
    {
        path: `${paths.team}/convert/:leadId`,
        component: lazy(() => import("../sales-manager/Convert")),
    },
    {
        path: `${paths.booking}/booking-details/:bookingId`,
        component: lazy(() => import("../sales-manager/BookingDetails")),
    },
    {
        path: paths.leadApproval,
        component: lazy(() => import("./LeadApproval")),
        exact: true,
    },
    {
        path: `${paths.leadApproval}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.reports,
        component: lazy(() => import("./Reports")),
        exact: true,
    },
    {
        path: paths.lead_reports,
        component: lazy(() => import("./LeadReports")),
        exact: true,
    },
    {
        path: `${paths.search}/view/:leadId`,
        component: lazy(() => import("../tele-caller/LeadDetails")),
    },
    {
        path: paths.search,
        component: lazy(() => import("../OverallSearchResult")),
    },
    {
        path: paths.CancelledBooking,
        component: lazy(() => import("./CancelledIndex")),
        exact: true,
    },
    {
        path: `${paths.CancelledBooking}/cancelled-booking/:customerId/:status`,
        component: lazy(() => import("./CancelledBooking/CancellationDisplayView")),
    },
];

export const allowRoles = [7];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Sales General Manager"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<BrouchureLinks />}
        />
    </AuthRoute>
);
