import React from "react"

const ModalContent = ({ children, className, style = {} }) => {
    return (
        <div className={className} style={style} onClick={e => e.stopPropagation()}>
            {children}
        </div>
    )
}

export default ModalContent
