import { useEffect, useReducer, useCallback, useState } from "react";
import clientApi from "../../apiService/clientApi";

export const init = {
    fetching: false,
    error: false,
    data: undefined,
};

export const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_INIT":
            return {
                ...state,
                fetching: true,
                error: false,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                fetching: false,
                error: false,
                data: action.payload,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                fetching: false,
                error: true,
            };
        case "FETCH_CLEAR":
            return init;
        default:
            throw new Error();
    }
};

const useFetchData = () => {
    const [url, set] = useState("");
    const [state, dispatch] = useReducer(dataFetchReducer, init);

    useEffect(() => {
        let didCancel = false;
        const fetchData = async (fetchUrl) => {
            dispatch({ type: "FETCH_INIT" });
            try {
                if (Array.isArray(fetchUrl)) {
                    const promises = fetchUrl.map((x) => clientApi({ url: x }));
                    const result = await Promise.all(promises);
                    const payload = result.map((x) => x.data);
                    if (!didCancel) {
                        dispatch({ type: "FETCH_SUCCESS", payload });
                    }
                } else {
                    const result = await clientApi({ url: fetchUrl });
                    if (!didCancel) {
                        dispatch({
                            type: "FETCH_SUCCESS",
                            payload: result.data,
                        });
                    }
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: "FETCH_FAILURE" });
                }
            }
        };
        if (url) {
            fetchData(url);
        }
        return () => {
            dispatch({ type: "FETCH_CLEAR" });
            didCancel = true;
        };
    }, [url]);

    const setUrl = useCallback((x) => {
        set(x);
    }, []);

    const clear = useCallback(() => {
        dispatch({ type: "FETCH_CLEAR" });
    }, []);

    const reload = useCallback((x) => {
        set("");
        set(x);
    }, []);

    return [
        state,
        {
            setUrl,
            reload,
            clear,
        },
    ];
};

export default useFetchData;
