import React from "react";
import useLockBodyScroll from "../../hooks/layout/useLockBodyScroll";

const ModalOverlay = ({ children, className, style = {}, onClick = null }) => {
    useLockBodyScroll(true);
    return (
        <div
            className={className}
            style={style}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default ModalOverlay;
