import React from "react";
import Pages from "./pages/Pages";
import { ToastContainer } from "react-toastify";
import UserProvider from "./components/auth/UserProvider";
import MasterData from "./components/common/MasterData";
import ProjectData from "./components/common/ProjectData";

const App = () => {
    return (
        <UserProvider>
            <MasterData>
                <ProjectData>
                    <Pages />
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </ProjectData>
            </MasterData>
        </UserProvider>
    );
};

export default App;
