import formTypes from "../../form/formTypes";

export const formKeys = {
    name: "name",
    number: "number",
    email: "email",
    address: "address",
    noOfCar: "no_of_carpark",
    amenities: "amenities_charges",
    baseRate: "base_rate",
    frc: "frc",
    plc: "plc",
};

export const form1 = [
    {
        name: formKeys.name,
        type: formTypes.text,
        label: "Customer Name",
        inputProps: {
            placeholder: "Enter name",
        },
        conClass: "col-md-6",
        required: false,
    },
    {
        name: formKeys.number,
        type: formTypes.text,
        label: "Customer Phone Number",
        inputProps: {
            placeholder: "Enter phone number",
        },
        conClass: "col-md-6",
        required: false,
    },
    {
        name: formKeys.email,
        type: formTypes.email,
        label: "Customer Email",
        inputProps: {
            placeholder: "Enter email",
        },
        conClass: "col-md-12",
        required: false,
    },
    {
        name: formKeys.address,
        type: formTypes.text,
        label: "Address",
        inputProps: {
            placeholder: "Enter address",
        },
        conClass: "col-md-12",
        required: false,
    },
];

export const form2 = [
    {
        name: formKeys.noOfCar,
        type: formTypes.text,
        label: "No. of Car Park",
        inputProps: {
            placeholder: "Enter number of car park",
        },
        conClass: "col-md-6",
        required: true,
    },
    {
        name: formKeys.amenities,
        type: formTypes.text,
        label: "Amenities Charges",
        inputProps: {
            placeholder: "Enter amenities charges",
        },
        conClass: "col-md-6",
        required: true,
    },
    {
        name: formKeys.baseRate,
        type: formTypes.text,
        label: "Basic Rate",
        inputProps: {
            placeholder: "Enter basic rate",
        },
        conClass: "col-md-4",
        required: true,
    },
    {
        name: formKeys.frc,
        type: formTypes.text,
        label: "FRC",
        inputProps: {
            placeholder: "Enter FRC",
        },
        conClass: "col-md-4",
        required: true,
    },
    {
        name: formKeys.plc,
        type: formTypes.text,
        label: "PLC",
        inputProps: {
            placeholder: "Enter PLC",
        },
        conClass: "col-md-4",
        required: true,
    },
];
