export const init = {
    values: {},
    errors: {},
    isSubmiting: false,
};

export default function formReducer(state, action) {
    switch (action.type) {
        case "on_change":
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.name]: action.value,
                },
                errors: {
                    ...state.errors,
                    [action.name]: "",
                },
            };
        case "set_submitting":
            return {
                ...state,
                isSubmiting: action.payload,
            };
        case "set_err":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.name]: action.error,
                },
            };
        case "set_errors":
            return {
                ...state,
                errors: action.payload,
            };
        case "set_values":
            return {
                ...state,
                values: action.payload,
            };
        case "reset_form":
            return {
                ...state,
                errors: {},
                values: {},
            };
        case "set_form":
            return { ...state, ...action.form };
        default:
            return state;
    }
}

export const setValue = (name, value) => {
    return {
        type: "on_change",
        name,
        value,
    };
};

export const setErr = (name, error) => {
    return {
        type: "set_err",
        name,
        error,
    };
};

export const setForm = (form = {}) => {
    return {
        type: "set_form",
        form,
    };
};

export const resetForm = () => {
    return {
        type: "reset_form",
    };
};

export const setSubmiting = (payload = false) => {
    return {
        type: "set_submitting",
        payload,
    };
};

export const setErrors = (payload = {}) => {
    return {
        type: "set_errors",
        payload,
    };
};

export const setValues = (payload = {}) => {
    return {
        type: "set_values",
        payload,
    };
};
