import React from "react";
import { Redirect } from "react-router-dom";
import Login from "../components/auth/Login";
import useUser from "../hooks/user/useUser";
import roleLanding from "./roleLanding";
import LoginLayout from "../components/auth/LoginLayout";

const Landing = () => {
    const { token, role } = useUser()[0];
    const userRole = role && role.id ? role.id : null;

    if (token && userRole) {
        let landing = roleLanding[userRole];
        return <Redirect to={landing ? landing : "/logout"} />;
    }
    return (
        <LoginLayout
            title="Sign In"
            desc="Welcome back! Please signin to continue.">
            <Login />
        </LoginLayout>
    );
};

export default Landing;
