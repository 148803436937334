import React, { useEffect } from "react";
import Loader from "../form/Loader";
import urls from "../../apiService/urls";
import useSubmit from "../../hooks/http/useSubmit";
import { useHistory } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import { salesManagerUrls } from "../../apiService/urls";
import { loadLocalState } from "../../utils/localstorage";

const Logout = () => {
    let history = useHistory();
    const [state, { clear }] = useUser();

    const loggedinfo = loadLocalState();
    
    const handleLogout = () => {
        clear();
        history.replace("/");
    };



    const initLogout = () => {
        submit({
            url: urls.logout,
            method: "POST",
        });
    }

    const [fetching1, clearAttenance] = useSubmit({
        success: initLogout,
        error: initLogout,
    });

    const [fetching, submit] = useSubmit({
        success: handleLogout,
        error: handleLogout,
        hideToast: true,
    });


    useEffect(() => {
        if (state.token) {
            const user_role = parseInt(state.role.id);
            if ((user_role === 3 || user_role === 4 ) && loggedinfo.current_project!=-1) {
                clearAttenance({
                    url: salesManagerUrls.attendance,
                    method: "POST",
                    data:{project:loggedinfo.current_project,type:2},
                    
                });
            }
            else {
                initLogout();
            }
        } else {
            handleLogout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="abs-full d-flex justify-content-center align-items-center">
            { (fetching || fetching1) && <Loader size={30} />}
        </div>
    );
};

export default Logout;
