import React from "react";
import { useHistory } from "react-router";
import urls from "../../apiService/urls";
import useFormatAll from "../../hooks/form/useFormatAll";
import FieldArray from "../form/FieldArray";
import Form from "../form/Form";
import FormSubmitBtn from "../form/FormSubmitBtn";
import validateAll from "../form/validateAll";

const allIds = [
    {
        name: "old_password",
        type: "password",
        label: "Old Password",
        inputProps: { placeholder: "Enter Old Password", },
        required: true,
        
        
       
    },
    {
        name: "new_password",
        type: "password",
        label: "New Password",
        inputProps: { placeholder: "Enter New Password" },
        required: true,
    },
    {
        name: "retype_password",
        type: "password",
        label: "Re-type Password",
        inputProps: { placeholder: "Re-type New Password" },
        required: true,
    },
];

const ChangePwd = () => {
    const { replace } = useHistory();
    const formatData = useFormatAll(allIds);

    const validateForm = (values) => {
        let errors = validateAll(allIds, values);
        const old = values?.old_password;
        const newPwd = values?.new_password;
        const rePwd = values?.retype_password;
        if (old && newPwd && old === newPwd) {
            Object.assign(errors, {
                new_password: "New Password cannot be same as old",
            });
            return errors;
        }
        if (rePwd && newPwd && rePwd !== newPwd) {
            Object.assign(errors, {
                retype_password: "Passwords do not match",
            });
            return errors;
        }
        return errors;
    };

    return (
        <Form
            validateForm={validateForm}
            formatData={formatData}
            config={{ url: urls.changePwd, method: "POST" }}
            resetOnSuccess
            handleSuccess={() => {
                replace("/logout");
            }}
        >
            <FieldArray allIds={allIds} />
            <FormSubmitBtn className="btn btn-block btn-theme">
                Submit
            </FormSubmitBtn>
        </Form>
    );
};

export default ChangePwd;
