import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import ProjectsDropDown from "../../components/layout/CrmProject";

// import BrouchureLinks from "../../components/layout/BrouchureLinks";
// import routes
export const pathName = "/legal-advisor";

export const paths = {
    legaladvisor: "/legaladvisor",

}

export const landing = `${pathName}${paths.legaladvisor}`;

export const menu = [
    {
        title: "Legal Advisor",
        to: landing,
    },
];

export const routes = [
    {
        path: `${paths.legaladvisor}/view/:customerId`,
        exact: true,
        component: lazy(() => import("./CustomerDetail")),
    },
    {
        path: paths.legaladvisor,
        exact: true,
        component: lazy(() => import("./legaladvisor")),
    },
    {
        path: `${paths.legaladvisor}/view_booking/:status/:customerId`,
        exact: true,
        component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
    },
    
];

export const allowRoles = [13];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Legal Advisor"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<ProjectsDropDown />}
        />
    </AuthRoute>
);