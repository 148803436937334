import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";

export const pathName = "/marketing-manager";

export const paths = {
  dashboard: "/dashboard",
  reports: "/reports",
  master: "/master-data",
  budget: "/campaign-budget",
  target: "/target",
  portalLeads: "/portal-leads",
  superReceptionist: "/super-receptionist",
  addBudget: "/campaign-budget/add",
  addProject: "/project/add",
  editProject: "/project/edit/:projectId",
  search: "/search",
  // sales: "/sales",
  salesoverall: "/salesoverall",
};

export const landing = `${pathName}${paths.dashboard}`;

export const pages = {
  landing,
  reports: `${pathName}${paths.reports}`,
  master: `${pathName}${paths.master}`,
  budget: `${pathName}${paths.budget}`,
  target: `${pathName}${paths.target}`,
  portalLeads: `${pathName}${paths.portalLeads}`,
  superReceptionist: `${pathName}${paths.superReceptionist}`,
  addBudget: `${pathName}${paths.addBudget}`,
  addProject: `${pathName}${paths.addProject}`,
  // sales: `${pathName}${paths.sales}`,
  salesoverall: `${pathName}${paths.salesoverall}`,
  editProject: (id) =>
    `${pathName}${paths.editProject.replace(":projectId", id)}`,
};
export const menu = [
  {
    title: "Dashboard",
    to: landing,
  },
  {
    title: "Reports",
    to: pages.reports,
  },
  {
    title: "Master Data",
    to: pages.master,
  },
  {
    title: "Campaign Budget",
    to: pages.budget,
  },
  {
    title: "Target",
    to: pages.target,
  },
  {
    title: "Portal Leads",
    to: pages.portalLeads,
  },
  {
    title: "Super Receptionist",
    to: pages.superReceptionist,
  },
  // {
  //     title: "Attendance",
  //     to: pages.sales,
  // }
  {
    title: "Accept Leads",
    to: pages.salesoverall,
  },
];

export const routes = [
  {
    path: paths.dashboard,
    component: lazy(() => import("./Dashboard")),
  },
  {
    path: paths.reports,
    component: lazy(() => import("./Reports")),
  },
  {
    path: paths.master,
    component: lazy(() => import("./MasterData")),
  },
  {
    path: paths.addProject,
    component: lazy(() => import("./AddProject")),
  },
  {
    path: paths.editProject,
    component: lazy(() => import("./EditProject")),
  },
  {
    path: paths.budget,
    component: lazy(() => import("./Budget")),
    exact: true,
  },
  {
    path: paths.addBudget,
    component: lazy(() => import("./AddBudget")),
  },
  {
    path: paths.target,
    component: lazy(() => import("./Target")),
  },
  {
    path: paths.portalLeads,
    component: lazy(() => import("./PortalLeads")),
  },
  {
    path: paths.superReceptionist,
    component: lazy(() => import("./SuperReceptionist")),
  },
  {
    path: `${paths.search}/view/:leadId`,
    component: lazy(() => import("../tele-caller/LeadDetails")),
  },
  {
    path: paths.search,
    component: lazy(() => import("../OverallSearchResult")),
  },

  // {
  //     path: paths.sales,
  //     component: lazy(() => import("./Sales")),
  // },
  {
    path: paths.salesoverall,
    component: lazy(() => import("./SalesOverall")),
  },
];

export const allowRoles = [5];

export default (
  <AuthRoute path={pathName}>
    <RoleRoutes
      title="Marketing Manager"
      routes={routes}
      menu={menu}
      landing={landing}
      allowRoles={allowRoles}
    />
  </AuthRoute>
);
