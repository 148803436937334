import React from "react";
import logo from "../../images/logo.png";

const LoginLayout = ({ title, desc, children }) => {
    return (
        <div className="login-page">
            <div className="login-box">
                <div className="flex-center mb-5">
                    <img height="40" src={logo} alt="" />
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="pb-2">
                            <h4>{title}</h4>
                            <p>{desc}</p>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginLayout;
