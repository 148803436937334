import React from "react";
import Loader from "../form/Loader";

const PageLoader = (props) => (
  <div className="flex-center mt-5 mb-5">
    <Loader {...props} />
  </div>
);

const DynamicContent = ({
  fetching,
  loader = null,
  isLoaded = false,
  children = null,
}) => {
  if (fetching) {
    return loader || <PageLoader />;
  }
  if (isLoaded) {
    return children;
  }
  return null;
};

export default DynamicContent;
