import React from "react";

export const HeaderMenuItem = ({ extraCls = "", children, style = {} }) => {
  return (
    <li className={`header-menu-item ${extraCls}`} style={style}>
      {children}
    </li>
  );
};

const HeaderMenu = ({ extraCls = "", children, style = {} }) => {
  return (
    <div className={`header-menu ${extraCls}`} style={style}>
      {children}
    </div>
  );
};

export default HeaderMenu;
