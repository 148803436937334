const local_key = "baashyaam-telecaller-user";
//load user from localstorage
export const loadLocalState = () => {
    try {
        const serializedState = localStorage.getItem(local_key);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

//save user to localstorage
export const saveLocalState = (state) => {
    try {
        const data = loadLocalState() || {};
        const serializedState = JSON.stringify({ ...data, ...state });
        localStorage.setItem(local_key, serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};

//save user to localstorage
export const deleteLocalUser = (state) => {
    try {
        localStorage.removeItem(local_key);
    } catch (err) {
        // Ignore write errors.
    }
};
