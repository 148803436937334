import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import urls from "../../apiService/urls";
import useFetchData from "../../hooks/http/useFetchData";
import useToggle from "../../hooks/useToggle";
import { isArray } from "../../utils";
import DynamicContent from "../common/DynamicContent";
import Modal from "../modal/Modal";
import { HeaderMenuItem } from "./HeaderMenu";

const ReportModal = () => {
    const [state, { setUrl }] = useFetchData();

    useEffect(() => {
        setUrl(urls.salesTeamReport);
    }, [setUrl]);

    const isLoaded = isArray(state?.data?.status_wise_report) && state.data.status_wise_report.length > 0;

    return (
        <DynamicContent isLoaded={isLoaded} fetching={state.fetching}>
            {isLoaded && <Report {...state.data} />}
        </DynamicContent>
    );
};

const Report = ({ status_wise_report, manager_wise_report }) => {
    const [active, setActive] = useState(status_wise_report[0]);

    return (
        <div className="p-3">
            <div className="tab-btn-list-con">
                {status_wise_report.map((x) => (
                    <button
                        className={`btn btn-tab${active?.title === x.title ? " btn-tab-active" : ""
                            }`}
                        onClick={() => setActive(x)}
                        key={x.title}
                    >
                        {x.title} ({x.count})
                    </button>
                ))}
                <button
                    className={`btn btn-tab${active?.title === "Manager Wise" ? " btn-tab-active" : ""
                        }`}
                    onClick={() => setActive(manager_wise_report)}
                    key={"Manager Wise"}
                >
                    Manager Wise
                    </button>
            </div>
            {isArray(active?.details) ? active.details.length > 0 && (
                <table className="table table-bordered">
                    <tbody>
                        {isArray(active.details) &&
                            active.details.map((x, idx) => (
                                <tr key={idx}>
                                    <td>{x.project}</td>
                                    <td>
                                        {isArray(x.users)
                                            ? x.users.map((x, idx) => (
                                                <div
                                                    className="d-flex justify-content-between pb-1"
                                                    key={idx}
                                                >
                                                    <span>{x.name}</span>
                                                    <span>{x.count}</span>
                                                </div>
                                            ))
                                            : "-"}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            ) : <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Manager Name</th>
                        <th>Today</th>
                        <th>This Month</th>
                        <th>Previouse Month</th>
                    </tr>
                </thead>
                <tbody>
                    {isArray(manager_wise_report) &&
                        manager_wise_report.map((x, idx) => (
                            <tr key={idx}>
                                <td>{x.name}</td>
                                <td>{x.today}</td>
                                <td>{x.this_month}</td>
                                <td>{x.previous_month}</td>
                            </tr>
                        ))}
                </tbody>
            </table>}
        </div>
    );
};

const SalesReport = () => {
    const { toggle, onTrue, onFalse } = useToggle();

    return (
        <HeaderMenuItem>
            <button onClick={onTrue} className="btn btn-sm btn-outline-black">
                Assigned lead count
            </button>
            {toggle && (
                <Modal isOpen close={onFalse}>
                    <button className="btn float-right" onClick={onFalse}>
                        <X />
                    </button>
                    <ReportModal />
                </Modal>
            )}
        </HeaderMenuItem>
    );
};

export default SalesReport;
