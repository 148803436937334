import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import ProjectsDropDown from "../../components/layout/CrmProject";

// import routes
export const pathName = "/fm";

export const paths = {
    dashboard:"/Dashboard",
    digitalapprove:"/digitalapprove",
    report:"/ReportModuleCRMHead",


}

export const landing = `${pathName}${paths.dashboard}`;
export const digitalapproval = `${pathName}${paths.digitalapprove}`;
export const report = `${pathName}${paths.report}`;



export const menu = [
    {
        title: "Dashboard",
        to: landing,
    },
    {
        title: "Digital Approval",
        to: digitalapproval,
    },
    {
        title: "Reports",
        to: report,
    },
];

export const routes = [
    {
        path: paths.dashboard,
        exact: true,
        component: lazy(() => import("./Dashboard")),
    },
    {
        path: paths.digitalapprove,
        exact: true,
        component: lazy(() => import("./digitalapprove")),
    },
    {
        path: paths.report,
        exact: true,
        component: lazy(() => import("./ReportModuleCRMHead")),
    },
    {
        path: `${paths.digitalapprove}/view_booking/:status/:customerId`,
        exact: true,
        component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
    },
    {
        path: `${paths.fm}/view_booking/:status/:customerId`,
        exact: true,
        component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
      },
    
];

export const allowRoles = [14];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="FM"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<ProjectsDropDown />}
        />
    </AuthRoute>
);