import React from "react";

const FieldCon = ({
  required = false,
  label = "",
  infoText = "",
  err = "",
  children,
  conClass = "",
  formGroupClass = "",
}) => {
  return (
    <div className={conClass}>
      <div className={`form-group ${formGroupClass}`}>
        {label && (
          <label className="form-label">
            {label} {required && <span className="form-asterix">*</span>}
          </label>
        )}
        {children}
        <ul className="input-messages">
          {infoText && <li className="infoText">{infoText}</li>}
          {err && <li className="errMsg">{err}</li>}
        </ul>
      </div>
    </div>
  );
};

export default FieldCon;
