import React from "react";
import ChangePwd from "../components/auth/ChnagePwd";
import LoginLayout from "../components/auth/LoginLayout";

const ChangePwdPage = () => {
    return (
        <LoginLayout
            title="Change Password"
            // desc="Change or create your password"
        >
            <ChangePwd />
        </LoginLayout>
    );
};

export default ChangePwdPage;
