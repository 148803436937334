import React from "react";
import Select from "react-select";

const SelectIp = ({
    options = [],
    setValue = null,
    placeholder = "",
    value = null,
    isLoading = false,
    ...ipProps
}) => {
    return (
        <Select
            value={value}
            placeholder={placeholder}
            options={options}
            classNamePrefix="react-select"
            onChange={(val) => {
                if (typeof setValue === "function") {
                    setValue(val);
                }
            }}
            isLoading={isLoading}
            {...ipProps}
        />
    );
};

export default SelectIp;
