import React from "react";
import FieldCon from "./FieldCon";
import SelectField from "./SelectField";
import useField from "../../hooks/form/useField";

const Field = ({
  type,
  name = "",
  label,
  required,
  infoText,
  conClass,
  inputProps,
  formGroupClass = "",
  formcontrolclass = "",
  inputval='',
  setValue = ''
}) => {
  const field = useField(name);
  if(inputval!='')
    field.value = inputval
  if(setValue!='')
    field.setValue = setValue
  return (
    <FieldIp
      type={type}
      {...field}
      name={name}
      label={label}
      required={required}
      infoText={infoText}
      conClass={conClass}
      inputProps={inputProps}
      formGroupClass={formGroupClass}
      formcontrolclass={formcontrolclass}
    />
  );
};

const FieldIp = ({
  type = "text",
  label,
  required,
  infoText,
  conClass,
  inputProps = {},
  name = "",
  err,
  value,
  setValue,
  formGroupClass = "",
  formcontrolclass = "",
}) => {
  return (
    <FieldCon
      err={err}
      required={required}
      label={label}
      infoText={infoText}
      conClass={conClass}
      formGroupClass={formGroupClass}
      formcontrolclass={formcontrolclass}
    >
      <SelectField
        type={type}
        value={value}
        setValue={setValue}
        name={name}
        formcontrolclass={formcontrolclass}
        {...inputProps}
      />
    </FieldCon>
  );
};

export default Field;