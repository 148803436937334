function extractError(err) {
    if (typeof err === "string") {
        return err;
    }
    if (Array.isArray(err)) {
        if (err.length > 0) {
            return extractError(err[0]);
        }
        return "Something went wrong";
    }
    if (typeof err === "object") {
        let keys = Object.keys(err);
        if (keys.length > 0) {
            return extractError(err[keys[0]]);
        }
        return "Something went wrong";
    }
    return "Something went wrong";
}

// ajax err message
function ajaxerrmsg(err) {
    if (err && err.response) {
        const { status, data } = err.response;
        switch (status) {
            case 406:
            case 400:
                return extractError(data || {});
            case 405:
                return "Method not allowed";
            case 404:
                return "Request Not Found";
            case 401:
                return "Unauthourized user";
            case 500:
            default:
                return "Server Error";
        }
    }
    return "Something Went Wrong";
}

export default ajaxerrmsg;
