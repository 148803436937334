import React from "react";
import { ChevronDown } from "react-feather";
import { useLocation } from "react-router-dom";
import { isArray } from "../../utils";
import DynamicContent from "../common/DynamicContent";
import { useMasterValues } from "../common/MasterData";
import { useProjectValues } from "../common/ProjectData";
import { HeaderMenuItem } from "./HeaderMenu";
import { InLineLoader } from "../form/Loader";

const WithProject = ({ projects }) => {
  const { crm_project, updateProject } = useProjectValues();

  // console.log("crm_project?.title:", crm_project?.title);

  const { pathname } = useLocation();
  const isListingPage = pathname.replace(/[^\d.]/g, "");
  return (
    <HeaderMenuItem extraCls="drop-menu-con">
      {crm_project?.title} <ChevronDown size={14} />
      {!isListingPage && (
        <ul className="drop-menu">
          {projects.map((x) => (
            <li key={x.id}>
              <div className="text-body" onClick={() => updateProject(x)}>
                {x.title}
              </div>
            </li>
          ))}
        </ul>
      )}
    </HeaderMenuItem>
  );
};

const ProjectsDropDown = () => {
  const [fetching, data] = useMasterValues();
  const isLoaded = isArray(data?.project) && data.project.length > 0;

  return (
    <DynamicContent
      isLoaded={isLoaded}
      fetching={fetching}
      loader={<InLineLoader />}
    >
      {isLoaded && <WithProject projects={data?.project} />}
    </DynamicContent>
  );
};

export default ProjectsDropDown;
