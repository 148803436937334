import React from "react";
import { Redirect, Route } from "react-router-dom";
import useUser from "../../hooks/user/useUser";

const AuthRoute = (props) => {
    const state = useUser()[0];

    if (!state.token) {
        return <Redirect to="/" />;
    }
    return <Route {...props} />;
};

export default AuthRoute;
