import React from "react";
import SubmitBtn from "./SubmitBtn";
import useForm from "../../hooks/form/useForm";

const FormSubmitBtn = ({ className = "btn btn-theme btn-block", ...props }) => {
    const [state] = useForm();

    return (
        <SubmitBtn
            className={className}
            {...props}
            fetching={state.isSubmiting}
        />
    );
};

export default FormSubmitBtn;
