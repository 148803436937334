import React, { lazy } from "react";
import AuthRoute from "../../components/auth/AuthRoute";
import RoleRoutes from "../RoleRoutes";
import ProjectsDropDown from "../../components/layout/CrmProject";
// import routes
export const pathName = "/auditor";
export const paths = {
  crm: "/crm",
  sales: "/sales",
};
export const landing = `${pathName}${paths.sales}`;
export const crm = `${pathName}${paths.crm}`;

export const menu = [
  {
    title: "Sales",
    to: landing,
  },
  {
    title: "CRM",
    to: crm,
  },
];

export const routes = [
  
    {
        path: paths.crm,
        exact: true,
        component: lazy(() => import("./crm")),
    },
    {
      path: paths.sales,
      exact: true,
      component: lazy(() => import("../../pages/crm-executives/sales_head")),
    },
    
    {
      path: `${paths.crm}/view/:customerId`,
      exact: true,
      component: lazy(() => import("../../pages/crm-executives/CRM/CustomerMaster/CustomerDetail")),
    },
    {
      path: `${paths.handover}/view/:customerId`,
      component: lazy(() => import("../../pages/crm-executives/LegalDocuments/Documents/LegalDetails")),
    },
    {
        path: `${paths.crm}/view_booking/:status/:customerId`,
        exact: true,
        component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
        
    },
    {
      path: `${paths.digital_approval}/view_booking/:status/:customerId`,
      exact: true,
      component: lazy(() => import("../../components/crm-executive/crm/DigitalApprove/DocumentList")),
    },
    {
      path: `${paths.sales}/view/:customerId`,
      // component: lazy(() => import("../../pages/crm-executives/CRM/CustomerMaster/CustomerDetail")),
      component: lazy(() => import("./../crm-executives/Sales/Cancellation/CancellationAdd")),
    },
    {
        path: `${paths.crmhead}/view/:customerId`,
        exact: true,
        component: lazy(() => import("../crm-executives/CRM/CustomerMaster/CustomerDetail")),
    },
    
    {
        path: `${paths.crm}/view/:customerId/final-notice`,
        exact: true,
        component: lazy(() =>
          import(
            '../../components/crm-executive/crm/PaymentDetailPage/TabSection/FinalRemainderLetter')),
        
    },
    {
        path: `${paths.crm}/payment/view/:customerId`,
        exact: true,
        component: lazy(() => import("../crm-executives/CRM/CustomerPayments/PaymentDetailPage")),
    },
    {
        path: `${paths.crm}/poa/add/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/Add/PoaInfoAdd")),
    },
    {
        path: `${paths.crm}/poa/:poaId/edit/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/editDetails/PoaDetailsEdit")
        ),
      },
      {
        path: `${paths.crm}/contact-person/add/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/Add/AddContactPerson")
        ),
      },
      {
        path: `${paths.crm}/contact-person/:conId/edit/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/editDetails/ContactPersonEdit")
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/generate-customer-receipt`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/GenerateCustomerReceipt"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/upr`,
        exact: true,
        component: lazy(() =>
          import(
            "../../pages/crm-executives/CRM/CustomerMaster/Upr/CustomerUpr"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/interest`,
        exact: true,
        component: lazy(() =>
        import(
          "../../pages/crm-executives/CRM/CustomerMaster/Interest/CustomerInterest"
        )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/payment-received-receipt`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/payment-received-receipt/:receiptId`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/payment-received-from-customer`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedReceipt"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/request/add/:requestId/`,
        exact: true,
        component: lazy(() =>
          import(
            "../../pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation"
          )
        ),
      },
    
      {
        path: `${paths.crm}/view/:customerId/generate-customer-receipt/payment-received-receipt/:receiptId`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/Receipts/PaymentReceivedDetail"
          )
        ),
      },
      {
        //path: `${paths.crm}/view/:customerId/crms/co-applicant/add/:customerId`,
        path: `${paths.crm}/co-applicant/add/:customerId`,
        component: lazy(() =>
          import("../../components/crm-executive/crm/Add/AddCoApplicant")
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/credit-notes/:noteId`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CreditNoteDetails"
          )
        ),
      },
      {
        path: `${paths.crm}/payment/view/:customerId/outstanding-payment`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerOutstandingPayments"
          )
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/generate-customer-receipt/outstanding-payment`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerOutstandingPayments"
          )
        ),
      },
      {
        path: `${paths.crm}/payment/view/:customerId/payment-statement`,
        exact: true,
        component: lazy(() =>
          import(
            "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerPaymentStatement"
          )
        ),
      },
      {
      path: `${paths.crm}/view/:customerId/generate-customer-receipt/payment-statement`,
      exact: true,
      component: lazy(() =>
        import(
          "../../components/crm-executive/crm/PaymentDetailPage/TabSection/CustomerPaymentStatement"
        )
      ),
  },

      {
        path: `${paths.sales}/tower-reports`,
        component: lazy(() => import("../sales-manager/TowerReport")),
      },

      {
        path: paths.sales,
        exact: true,
        component: lazy(() => import("../../pages/crm-executives/sales")),
      },
      {
        path: `${paths.sales}/view/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/CRM/CustomerMaster/CustomerDetail")),
      },
      {
        path: `${paths.legal_documents}/view/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/LegalDocuments/Documents/LegalDetails")),
      },
      {
        path: `${paths.legal_documents}/register/:customerId`,
        component: lazy(() => import("../crm-executives/LegalDocuments/Registration/RegistrationDetails")),
      },
      {
        path: `${paths.sales}/cancellationview/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/CRM/CustomerMaster/CustomerDetail")),
      },
      {
        path: `${paths.sales}/cancellation/add/:customerId`,
        component: lazy(() => import("../crm-executives/Sales/Cancellation/CancellationAdd")),
      },
      // {
      //   path: `${paths.sales}/crm-executive/crm/CustomerDetailsPage/Requestimformation/:customerId`,
      //   component: lazy(() => import("./CRM/CustomerMaster/CancelledCustomer")),
      // },
      {
        path: `${paths.sales}/pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation`,
        component: lazy(() => import("./../../pages/crm-executives/CRM/CustomerMaster/Request/RequestInformation")),
      },
      {
        path: `${paths.sales}/cancellationdisplay/view/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/Sales/Cancellation/CancellationDisplayView")),
      },
      {
        path: `${paths.sales}/booking_form/:bookingId`,
        component: lazy(() => import("../../pages/crm-executives/Sales/BookingForm/BookingForm")),
      },
      {
        path: `${paths.sales}/apartment/:apartmentId`,
        component: lazy(() => import("../sales-manager/Apartment.js")),
      },
      {
        path: `${paths.sales}/tower-wise-availability`,
        component: lazy(() => import("../sales-manager/TowerAvailability.js")),
      },
      {
        path: `${paths.sales}/tower-reports`,
        component: lazy(() => import("../sales-manager/TowerReport")),
      },
      {
        path: `${paths.sales}/project-cost-sheet`,
        component: lazy(() => import("../sales-manager/ProjectCostSheet")),
      },
      {
        path: `${paths.sales}/booking-details/:bookingId`,
        exact: true,
        component: lazy(() => import("../../pages/crm-executives/Sales/BookingForm/BookingDetails")),
      },
    
      {
        path: `${paths.crm}/bank-details/add/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/Add/AddBankDetail")
        ),
      },
      {
        path: `${paths.crm}/bank-details/edit/:customerId`,
        exact: true,
        component: lazy(() =>
          import("../../components/crm-executive/crm/editDetails/EditBankDetails")
        ),
      },
      {
        path: `${paths.crm}/view/:customerId/crms/co-applicant/add/:customerId`,
        component: lazy(() =>
          import("../../components/crm-executive/crm/Add/AddCoApplicant")
        ),
      },
      {
        path: `${paths.legal_documents}/apartment-plan/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/LegalDocuments/Registration/InduvidualPlan")
      ),
      },
      {
        path: `${paths.legal_documents}/aadhar-pan/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/LegalDocuments/Registration/CustomerAadharTab")
      ),
      },
      {
        path: `${paths.legal_documents}/registration-remainder/:customerId`,
        component: lazy(() => import("../../pages/crm-executives/LegalDocuments/Registration/CustomerRegistrationRemainderTab")
      ),
      },
      {
        path: paths.digital_approval,
        exact: true,
        component: lazy(() => import("../../pages/crm-executives/CRM/digitalapprove")),
      },
    // {
    //     path: `${paths.crmhead}/view_apthandover_approval/:customerId`,
    //     exact: true,
    //     component: lazy(() => import("../../pages/crm-head/")),
    //},      
   
];


export const allowRoles = [21];

export default (
    <AuthRoute path={pathName}>
        <RoleRoutes
            title="Auditor"
            routes={routes}
            menu={menu}
            landing={landing}
            allowRoles={allowRoles}
            headerRight={<ProjectsDropDown />}
        />
    </AuthRoute>
);
