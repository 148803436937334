import React, {useState} from "react";
import { Helmet } from "react-helmet";
import useUser from "../../hooks/user/useUser";
import Header from "./Header";
import Menu from "./Menu";
//import { AttendanceInfo } from "../sales-manager/attendance/AttendanceInfo";
const Layout = ({ title = "Telecaller", children, menu, headerRight }) => {
  const { token } = useUser()[0];
  const user = JSON.parse(localStorage.getItem('baashyaam-telecaller-user'));
  const user_role = user.role.id;
    
  return (
    <>
      <Helmet titleTemplate="%s | BSS">
        <title>{title}</title>
      </Helmet>
      <div className="sticky-header">
        <Header headerRight={headerRight} />
        {token && <Menu menu={menu} />}
        {/* {((user_role === 3 || user_role === 4)) && <AttendanceInfo title={"Attendance Details"} />} */}
      </div>
      {children}
    </>
  );
};

export default Layout;
