import { Editor } from "@tinymce/tinymce-react";
import React from "react";

const HtmlEditor = ({
  setValue = null,
  className = "mx-0",
  height = 500,
  ...rest
}) => {
  return (
    <div className={className}>
      <Editor
        {...rest}
        apiKey="9dd4zbfqc2uuzpxa7tbphp1ijtc44mj5jcppsy7jio03l20i"
        onEditorChange={(newValue, editor) => {
          if (typeof setValue === "function") {
            setValue(newValue);
          }
        }}
        init={{
          height: height,
          menubar: false,
          default_link_target: "_blank",

          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "image",
            "image code",
            "table",
          ],
          toolbar: [
            "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "table tabledelete | tableprops tablerowprops tablecellprops |" +
              "tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
              "removeformat | image media link |" +
              "| help",
          ],

          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </div>
  );
};

export default HtmlEditor;
