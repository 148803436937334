import React from "react";
import { useLocation, useParams } from "react-router";
import urls, { salesManagerUrls } from "../../apiService/urls";

const CoastSheetIframe = () => {
    const { apartmentId } = useParams();
    const { search } = useLocation();
    const src = `${urls.baseURL}${salesManagerUrls.costSheet}${apartmentId}/${search}`;


    return (
        <iframe
            src={src}
            className="modal-wrapper"
            title="cost-sheet"
            style={{ border: "none", padding: "0px" }}
        />
    );
};

export default CoastSheetIframe;
